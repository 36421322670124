import { ThemeConfig } from './themeConfig';
import { translate } from '../utils/translations';
import { SiteLanguage } from 'constants/locale';
import keywordsCA from './keywordsCA';

export const EXP_MENU_LINK_LABEL_SIGN_IN = 'Sign In' as const;
export const EXP_MENU_LINK_LABEL_MY_SEARCHES = 'My Searches' as const;
export const EXP_CANADA_ADDRESS = '800 - 1070 Douglas Street,\nVictoria, BC, V8W2C4' as const;

const ExprealtyCaTheme: ThemeConfig = {
  name: 'eXp Realty',
  title: 'eXp Realty - eXp Realty® Canada',
  faviconPath: 'favicon-exp.ico',
  metaDescription: 'Largest residential real estate brokerage in North America. Where the pros go to grow.',
  largeDesktopHeaderImage: '/next/assets/images/header-image-large-desktop-exp-ca.jpg',
  desktopHeaderImage: '/next/assets/images/header-image-desktop-exp-ca.jpg',
  mobileHeaderImage: '/next/assets/images/header-image-mobile-exp-ca.jpg',
  smallMobileImage: '/next/assets/images/header-image-small-mobile-exp-ca.jpg',
  ogSiteName: 'eXp Realty® Canada',
  ogImage: 'https://exprealty.ca/assets/images/header-image2.jpg',
  schemaUrl: 'https://www.exprealty.ca',
  schemaLogo: 'https://exprealty.ca/next/assets/images/eXp-Realty-logo.png',
  schemaLocation: {
    addressCountry: 'Canada',
    addressLocality: 'Victoria',
    addressRegion: 'BC',
    postalCode: 'V8W2C4',
    streetAddress: '800 - 1070 Douglas St.',
  },
  schemaFoundingDate: '2002',
  schemaFounders: [
    {
      '@type': 'Person',
      name: 'Sanford Glenn Darrel',
    },
    {
      '@type': 'Person',
      name: 'Brian Culhane',
    },
  ],
  colors: {
    primaryVeryLight: '#8ECFFF',
    primaryLight:'#E8EDF5',
    primary: '#0C0F24',
    primaryDark: '#0C0F24',
    primaryVeryDark: '#0C0F24',
    primaryBackground: '#FFF',
    primaryForSale: '#FFF',
    primarySold: '#6D071A',
    primarySoldFocus: '#6D071A',
    primaryFilters: '#0C0F24',
    primaryFontColor: '#0C0F24',
    primaryLinkColor: '#1B489B',
    primaryFocusLinkColor: '#1B489B',
    primaryInvertedBackground: '#FFFFFF',
    primaryBorderColor: '#5A5A5A',
    primaryFocusColor: '#F3F4F4',
    primaryFocusBorderColor: '#0C0F24',
    primaryChart: '#F3F3F4',
    secondaryBackground: '#FFF',
    secondaryBorderColor: '#5A5A5A',
    secondaryLinkColor: '#3D3F50',
    secondaryHoverColor: '#0C0F24',
    secondaryFocusColor: '#3D3F50',
    secondaryFocusBorderColor: '#5A5A5A',
    tertiaryBackground: '#EFEFEF',
    tertiaryBackgroundLight: '#3D3F50',
    tertiaryBorderColor: '#5A5A5A',
    tertiaryLinkColor: '#1B489B',
    quaternaryLinkColor: '#496DAF',
    quaternaryBorderColor: '#5A5A5A',
    quaternaryBackgroundLight: '#FFFFFF',
    transparentBackground: 'rgba(255, 255, 255, 0.1)',
    mapPinForSale: '#1B489B',
    mapPinForSaleFocus: '#1B489B',
    mapControlBackground: '#FFF',
    mapControlBackgroundHover: '#F3F4F4',
    mapControlBackgroundActive: '#E7E7E9',
    carouselArrowHover: '#F3F4F4',
    carouselArrowFocus: '#CECFD3',
    notFound404PinHeadColor: '#1B489B',
    notFOund404PinBodyColor: '#496DAF',
  },
  fonts: {
    heading: 'Manrope',
    text: 'Manrope',
    headingWeight: '300',
    headingBoldWeight: '600',
  },
  decorations: {
    brushstrokes: false,
    boxShadow: '0px 2px 4px 0px #00000014',
    boxShadowLight: '0 0 8px 0 rgba(0, 0, 0, .1)',
    areaPageLineWidth: '51px',
  },
  buttons: {
    borderRadius: '100px',
    borderRadiusSmall: '8px',
    borderRadiusMedium: '8px',
    borderRadiusLarge: '100px',
    borderRadiusModal: '12px',
    borderRadiusBanner: '12px',
  },
  icons: {
    borderRadius: '100px',
  },
  style_overrides: {
    breadcrumbs: {
      mobileMarginTop: '1.5em',
      boxShadow: '0px 2px 4px 0px #00000014;',
    },
    areaListingsPage: {
      pageTitleFontWeight: 'bold',
    },
    locationUnavailable: {
      imagePath: '/next/assets/images/listing-page/location-unavailable-exp.svg',
    },
  },
  organization: {
    phoneNumber() {
      return '833-303-0610';
    },
    email() {
      return 'info@exprealty.net';
    },
    address() {
      return EXP_CANADA_ADDRESS;
    },
    twitterUrl: 'https://twitter.com/exprealtycanada',
    facebookUrl: 'https://www.facebook.com/exprealtycanada',
    instagramUrl: 'https://www.instagram.com/expcanada_?igsh=YmtqZzRqZHR1Z2Yw&utm_source=qr',
    linkedinUrl: 'https://www.linkedin.com/showcase/exprealtycanada',
    youtubeUrl: 'https://www.youtube.com/@eXpRealty',
    joinSectionUrl: 'https://join.exprealty.com/join-exp-canada/',
  },
  menuLinks: [
    {
      label: EXP_MENU_LINK_LABEL_MY_SEARCHES,
      link: '/account/saved/searches',
    },
    {
      label: 'Search',
      link: '/search',
    },
    {
      label: 'Buy',
      link: '/buy',
    },
    {
      label: 'Sell',
      link: '/sell',
    },
    {
      label: 'Find An Agent',
      link: '/agents-search',
    },
    {
      label: 'Join eXp',
      link: 'https://join.exprealty.com/join-exp-canada/',
      newTab: true,
    },
    {
      label: 'I\'m an eXp agent',
      link: 'https://my.exprealty.com/login',
      newTab: true,
    },
    {
      label: 'About',
      link: '/explore-exp',
    },
    {
      label: 'Sitemap',
      link: '/sitemap',
    },
    {
      label: 'Accessibility',
      link: '/accessibility',
    },
  ],
  footerContent: {
    column1: [
      {
        label: 'Explore eXp',
        link: '/explore-exp',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Find an Agent',
        link: '/agents-search',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Join eXp',
        link: 'https://join.exprealty.com/join-exp-canada/',
        target: '_blank',
      },
      {
        label: 'Investor Relations',
        link: 'https://expworldholdings.com/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Company Careers',
        link: 'https://expworldholdings.com/careers/',
        target: '_blank',
        isAnchor: true,
      },
    ],
    column2: [
      {
        label: 'eXp Life',
        link: 'https://life.exprealty.com/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Zoocasa',
        link: 'https://zoocasa.com/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Success',
        link: 'https://www.success.com/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Quebec Listings',
        link: 'https://expquebec.com/',
        target: '_blank',
        isAnchor: true,
      },
    ],
    column3: [
      {
        label: 'Terms and Conditions',
        link: 'https://expworldholdings.com/terms-of-service/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Privacy Policy',
        link: 'https://expworldholdings.com/privacy-policy/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Cookie Policy',
        link: 'https://expworldholdings.com/cookie-policy/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Modalités',
        link: 'https://expworldholdings.com/terms-of-service-fr/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Politique de confidentialité',
        link: 'https://expworldholdings.com/privacy-policy-fr/',
        target: '_blank',
        isAnchor: true,
      },
      {
        label: 'Sitemap',
        link: '/sitemap',
        target: '_blank',
        isAnchor: true,
      },
    ],
    bottom: [
      [
        {
          label: 'The trademarks MLS®, Multiple Listing Service® and the associated logos are owned '
            + 'by The Canadian Real Estate Association (CREA) and identify the quality of services provided by real estate '
            + 'professionals who are members of CREA. eXp Realty holds real estate brokerage licenses in multiple provinces. '
            + 'For information on licences please contact us at info@exprealty.net\n\n',
        },
      ],
      [
        {
          label: 'For listings in Canada, the trademarks REALTOR®, REALTORS®, and the REALTOR® logo are controlled by The '
            + 'Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA. The '
            + 'trademarks MLS®, Multiple Listing Service® and the associated logos are owned by CREA and identify the '
            + 'quality of services provided by real estate professionals who are members of CREA. Used under license.\n\n',
        },
      ],
    ],
    joinUsLink: 'https://join.exprealty.ca',
    footerImageData: {
      src: '/next/assets/images/exp-footer-pic-ca.png',
      alt: 'Two people smiling while looking at a phone.',
    },
  },
  searchOptions: [
    {
      type: 'Buy',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Rent',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Sold',
      placeholder(siteLocation, isOnMobile) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return isOnMobile ? `City, ${neighbourhood}, or address` : `Enter a city, ${neighbourhood}, address, MLS® number or school`;
      },
    },
    {
      type: 'Agents',
      placeholder(siteLocation) {
        const neighbourhood = translate('neighbourhood', siteLocation);
        return `Enter a city or ${neighbourhood}`;
      },
    },
    {
      type: 'HomeAppraisal',
      placeholder() {
        return 'Enter your home address';
      },
    },
  ],
  contentBlockBuy: {
    title: 'Buying your next home',
    copy: 'eXp Realty transforms home buying into a seamless experience, tailored to your needs with cutting-edge technology and expert support. Discover a better way to buy a home with eXp.',
    image: '/next/assets/images/content-block/exp-buy.jpg',
    link: '/buy',
  },
  contentBlockSell: {
    title: 'Selling your home',
    copy: 'Discover a smarter selling experience with eXp Realty with a simplified process that blends advanced technology and market insight to sell your home effectively and efficiently.',
    image: '/next/assets/images/content-block/exp-sell.jpg',
    link: '/sell',
  },
  privacyPolicy: (language?: SiteLanguage) => {
    switch (language) {
    case SiteLanguage.French:
      return 'https://expworldholdings.com/privacy-policy-french/';
    default:
      return 'https://expworldholdings.com/privacy-policy/';
    }
  },
  termsOfUse: (language?: SiteLanguage) => {
    switch (language) {
    case SiteLanguage.French:
      return 'https://expworldholdings.com/terms-of-service-french/';
    default:
      return 'https://expworldholdings.com/terms-of-service/';
    }
  },
  keywords: keywordsCA,
};

export default ExprealtyCaTheme;
